
// ajaxit
(function(theme, $) {

    'use strict';

    var PluginAjaxit = function($el, opts) {

        var Ajaxit = {
            opts: {},
            element: false,
            initialize: function(el, opts){
                if ($(el).data().ready) return true;

                opts.element = el;
                this.opts = opts;

                this.triggers().autoload();

                $(el).data().ready = true;

                return this;
            },
            /**
             * Take action depending on response
             * @param response
             */
            success: function(response){
                var _self = this;
                var action = (_self.opts.action)?_self.opts.action:'replace';
                var object = (_self.opts.actiontarget)?$(_self.opts.actiontarget):_self.opts.element;
                switch (action){
                    case 'delete':
                        object.fadeOut(500);
                        break;
                    case 'append':
                        object.append(response);
                        break;
                    case 'prepend':
                        object.prepend(response);
                        break;
                    case 'parent-reload':
                        parent.location.reload();
                        break;
                    default: // replace
                        object.empty().append(response);
                        break;
                }
                //$(response).find('.sidebarit').tmmSidebarit();
                $(response).find('.ajaxit').tmmAjaxit();

            },
            /**
             * Show overlay loading on an element, ajaxit element should have data-overlay="#id-of-element-or-jquery-selector"
             * @param target
             */
            showOverlay: function(){

                var _self = this;


                $(_self.opts.overlay).block({ message: null });


                return true;
            },
            /**
             * Hide overlay loading on an element, ajaxit element should have data-overlay="#id-of-element-or-jquery-selector"
             * @param target
             */
            hideOverlay: function(){
                var _self = this;

                $(_self.opts.overlay).unblock();

            },
            /**
             * Show Confirmation message based on text in data-confirm atrribute
             */
            confirm: function(){
                var _self = this;

                var target = $(_self.opts.target);

                _self.opts.scope = this;

                target.tmmModal(_self.opts, _self.go, function(){ return true; });
            },
            /**
             * load an ajax object written with my own "standard"
             */
            go: function(){

                var _self = this;

                var url = _self.opts.url;

                if (_self.opts.startDate){
                    var parameterSeparator = '?';
                    if (url.indexOf('?')!==-1)
                        parameterSeparator = '&';

                    url+= parameterSeparator+'startDate='+_self.opts.startDate;
                }

                if (_self.opts.endDate){
                    var parameterSeparator = '?';
                    if (url.indexOf('?')!==-1)
                        parameterSeparator = '&';

                    url+= parameterSeparator+'endDate='+_self.opts.endDate;
                }

                var overlay = _self.opts.overlay;

                if (overlay){
                    _self.showOverlay();
                }

                var method = _self.opts.method?_self.opts.method:"GET";

                var data = _self.opts.ajaxsenddata?_self.opts.ajaxsenddata:{};

                $.ajax({
                    url: url,
                    type: method,
                    data: data,
                    success: function (response) {
                        _self.success(response);

                        if (overlay){
                            _self.hideOverlay($(overlay));
                        }

                    },
                    error: function () {
                        swal('Error',_self.opts.onerrormessage?_self.opts.onerrormessage:"Sorry, there was an unexpected error with this action.", "error");

                        if (overlay){
                            _self.hideOverlay();
                        }

                    }
                });
                return false;
            },
            /**
             * Auto Load object marked to load automatically with the data-load attribute
             */
            autoload: function(className){

                var _self = this;

                if (_self.opts.load == "load")
                    _self.run();

                return this;
            },
            run: function(){

                var _self = this;

                if (_self.opts.confirm)
                    _self.confirm();
                else
                    _self.go();

                return this;
            },
            triggers: function(){

                var _self = this;

                if (!_self.opts.ajaxittriggers)
                    return this;

                var triggers  = _self.opts.ajaxittriggers?_self.opts.ajaxittriggers:{};

                for (var ev in triggers) {
                    if (triggers.hasOwnProperty(ev)) {

                        $('body').on(ev, triggers[ev], function(e){

                            _self.run();

                            e.preventDefault();
                            return false;
                        });

                    }
                }


                return this;
            }
        };

        return Ajaxit.initialize($el, opts);

    };

    // expose to scope as PluginAjaxit
    $.extend(theme, {
        PluginAjaxit: PluginAjaxit
    });

    // jquery plugin
    $.fn.tmmAjaxit = function(opts) {

        return this.each(function() {

            var $this = $(this);

            return new PluginAjaxit($this,  $this.data());

        });
    }

    $('.ajaxit').tmmAjaxit();



    /**
     * Dont need to manually callit
     */
    //Ajaxit.initialize();

}).apply(this, [window.theme, jQuery ]);

