(function($) {
    $("body").tooltip({ selector: '[data-toggle="tooltip"]' });

    $("body").on("click", "a[href=#]", function(){ return false;});

    $("body").on("click", ".form-submitter", function(){
        $action = $('#'+$(this).data().submitform).attr('action')+($(this).data().params?$(this).data().params:'');
        $('#'+$(this).data().submitform).attr('action',$action).submit();
        return false;
    });

    $('input.slugify').change(function() {
        var $that = $(this);
        var text = $that.val();
        var slug = slugify(text);

        $that.val(slug);
    });
}).apply(this, [jQuery]);

function slugify(text)
{
    return text.toString().toLowerCase().trim()
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/&/g, 'and')           // Replace & with 'and'
        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
        .replace(/\-\-+/g, '-')         // Replace multiple - with single -
        .replace(/^-+/, '')             // Trim - from start of text
        .replace(/-+$/, '');            // Trim - from end of text
}