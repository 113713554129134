
/**
 * Toggle a target element visibility and change icon of the triggerer
 */
(function(theme, $) {

    'use strict';


    var PluginTooglit = function($el) {

        var Togglit = {
            options: {},
            initialize: function(el){
                var _self = this;

                _self.options = $(el).data();

                _self.options.element = $(el);

                if (_self.options.element.data().ready)
                    return;
                else
                    _self.options.element.data().ready = true;

                _self.events();


                return this;

            },
            events: function(e){
                var _self = this;

                $(_self.options.element).on('click', function(e){
                    if ($(_self.options.target).css('display') == 'none')

                        _self.show.call(_self);

                    else

                        _self.hide.call(_self);;

                    e.preventDefault();

                    return false;
                });
            },
            show: function(){


                var _self = this;

                _self.options.element.addClass('active');

                $(_self.options.target).slideDown(500);
                $(_self.options.target).removeClass('hidden');
                $(_self.options.target).removeClass('hidden-xs');
                $(_self.options.target).removeClass('hidden-sm');
                $(_self.options.target).removeClass('hidden-md');
                $(_self.options.target).removeClass('hidden-lg');


                _self.options.element.find('.'+_self.options.replace).removeClass(_self.options.replace).addClass(_self.options.with);

            },
            hide: function(){

                var _self = this;

                _self.options.element.removeClass('active');

                $(_self.options.target).slideUp(500);

                _self.options.element.find('.'+_self.options.with).removeClass(_self.options.with).addClass(_self.options.replace);

            }

        };

        return Togglit.initialize($el);

    };

    // expose to scope as PluginAjaxit
    $.extend(theme, {

        PluginTooglit: PluginTooglit

    });

    // jquery plugin
    $.fn.tmmTogglit = function() {

        return this.each(function() {

            var $this = $(this);

            return new PluginTooglit($this);

        });
    }

    $('.togglit').tmmTogglit();

}).apply(this, [window.theme, jQuery ]);
