
/**
 * Will display sweet alert confirm box using html4 attributes
 */
(function(theme, $) {

    'use strict';


    var PluginModal = function($el, opts, confirmCallback, cancelCallback) {

        var Modal = {
            opts: {},
            initialize: function(el, opts, confirmCallback, cancelCallback){
                opts.element = el;

                var _self = this;

                _self.opts  = opts;

                var message = _self.opts.confirm?_self.opts.confirm:"Are you sure to do this?";
                var title = _self.opts.confirmtitle?_self.opts.confirmtitle:"Are you sure?";
                var confirmType = _self.opts.confirmtype?_self.opts.confirmtype:"info";
                var showCancelButton = _self.opts.showcancel?_self.opts.showcancel:true;
                var cancelButtonText = _self.opts.showcanceltext?_self.opts.showcanceltext:"Cancel";

                switch (confirmType){
                    case "error":
                        var confirmButtonText = _self.opts.confirmbuttontext?_self.opts.confirmbuttontext:"OK";
                        var confirmButtonColor = _self.opts.confirmbuttoncolor?_self.opts.confirmbuttoncolor:"#d2322d";
                        break;
                    case "warning":
                        var confirmButtonText = _self.opts.confirmbuttontext?_self.opts.confirmbuttontext:"OK";
                        var confirmButtonColor = _self.opts.confirmbuttoncolor?_self.opts.confirmbuttoncolor:"#ed9c28";
                        break;
                    default: // case "info"
                        var confirmButtonText = _self.opts.confirmbuttontext?_self.opts.confirmbuttontext:"OK";
                        var confirmButtonColor = _self.opts.confirmbuttoncolor?_self.opts.confirmbuttoncolor:"#08C";
                        break;

                }

                swal({
                        title: title,
                        text: message,
                        type: confirmType,
                        showCancelButton: showCancelButton, //"info", "error"
                        confirmButtonColor: confirmButtonColor,
                        confirmButtonText: confirmButtonText,
                        cancelButtonText: cancelButtonText,
                        closeOnConfirm: true
                    },
                    function(isConfirm){
                        if (isConfirm) {


                            //var parentScope = opts.parentObject;

                            //confirmCallback.call(parentScope);
                            confirmCallback.call(opts.scope);

                            return true;
                        } else {

                            //var parentScope = opts.parentObject;
                            //var callbackd = opts.confirmCancelback;

                            cancelCallback.call(opts.scope);
                            //callbackd.call(parentScope, {});

                            return true;
                        }
                    });

                return this;

            },
            /**
             * Contains a reference to the jquery object inserted in dom as modal
             */
        };

        return Modal.initialize($el, opts, confirmCallback, cancelCallback);

    };

    // expose to scope as PluginAjaxit
    $.extend(theme, {

        PluginModal: PluginModal

    });

    // jquery plugin
    $.fn.tmmModal = function(opts, confirmCallback, cancelCallback) {

        return this.each(function() {

            var $this = $(this);

            return new PluginModal($this, opts, confirmCallback, cancelCallback);

        });
    }
}).apply(this, [window.theme, jQuery ]);
