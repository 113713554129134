
/**
 * Sidebar it, displays content in the right sidebar
 */
(function(theme, $) {

    'use strict';

    var SidebaritListenForUpdate = function(){};

    $('.sidebar-right-content-frame').load(function(){

        $('.sidebar-right-content-frame').parent().unblock();

    });

    $('body').on('sidebarUpdateOrNewObject',function(){

        SidebaritListenForUpdate();

    });



    var PluginSidebarit = function($el) {

        var Sidebarit = {
            options: {
                sidebar: '#sidebar-right'
            },
            initialize: function(el){
                var _self = this;

                _self.options.element = el;

                _self.run();

                return this;

            },

            run: function(){
                var _self = this;

                var $contentUrl = $(_self.options.element).data().url;

                // if ($('.sidebar-right-content-frame').attr('src')!=$contentUrl){

                $('.sidebar-right-content-frame')[0].contentDocument.body.innerHTML = '';
                $('.sidebar-right-content-frame').parent().block();
                $('.sidebar-right-content-frame').attr('src', $contentUrl);

                var $fn = false;

                var $action = '';

                if ($($action = (_self.options.element).data().refresh) == 'page')
                    $fn = window.location.reload;
                else if ($action && $($action).data())
                    $fn = function(){
                        $($action).data().ready = false; // set to ajaxit that we need to refresh
                        $($action).tmmAjaxit();
                    }

                if ($fn)
                    SidebaritListenForUpdate = $fn;
                // }


                // open sidebar
                _self.open();



                return false;
            },
            open: function(){
                var _self = this;

                var isOpened = $('html').hasClass( 'sidebar-right-opened' );

                if ( isOpened ) {
                    return true;
                }

                $('html').addClass( 'sidebar-right-opened' );

                $(window).trigger( 'sidebar-right-toggle', {
                    added: true,
                    removed: false
                });

                $('html').on('click.mobile-close', function(e) {
                    e.stopPropagation();
                    _self.close(e);
                });

                $('html').on('click.close-right-sidebar', function(e) {
                    e.stopPropagation();
                    _self.close(e);
                });

                $(document).keyup(function(e) {
                    if (e.which == 27)
                        _self.close(e); // esc   (does not work)
                });
            },

            close: function(e) {
                if ( !!e && !!e.target && ($(e.target).closest( '.sidebar-right' ).get(0) || !$(e.target).closest( 'html' ).get(0)) ) {
                    e.preventDefault();
                    return false;
                }

                $('html').removeClass( 'sidebar-right-opened' );

                $('html').off( 'click.close-right-sidebar' );

                $('html').off( 'click.mobile-close' );

                $(window).trigger( 'sidebar-right-toggle', {
                    added: false,
                    removed: true
                });

                //_self.sidebars.right.isOpened = false;
            }

        };

        return Sidebarit.initialize($el);

    };

    // expose to scope as PluginAjaxit
    $.extend(theme, {

        PluginSidebarit: PluginSidebarit

    });

    // jquery plugin
    $.fn.tmmSidebarit = function() {

        return this.each(function() {

            var $this = $(this);

            return new PluginSidebarit($this);

        });
    }

    $('body').on('click', '.sidebarit', function(e){ $(this).tmmSidebarit();e.preventDefault(); return false; });

    $('body').on('click','.mobile-close',function(){
        console.log('close');
        $('html').trigger('click.close-right-sidebar');
        parent.$('html').trigger('click.close-right-sidebar');
    });


    //$('.sidebarit').tmmSidebarit();

}).apply(this, [window.theme, jQuery ]);

