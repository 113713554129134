(function( $ ) {

    // pdf sample
    /*$(".pdfFile").fileinput({
        showUpload: false,
        maxFileCount: 1,
        showCaption: true,
        browseIcon: '<i class="fa fa-folder-open"></i>',
        previewFileIcon: "<i class='fa fa-2x fa-file-pdf-o'></i>"
    }); */

    if ($.fn.fileinput && $('.input-files').length) {

        var $extraData = {};
        $(".input-files").each(function () {
            $(this).fileinput().on('filebatchuploadsuccess', function (event, data) {
                if ($(inputFile).data().onUploadSuccess){
                    var fn = window[$(inputFile).data().onUploadSuccess];
                    if(typeof fn === 'function') {
                        fn();
                    }

                }

            });
        });
    }

}).apply( this, [ jQuery ]);
