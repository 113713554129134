

// Select2 init
(function( $ ) {

    'use strict';

    //function initSelect2Objects() {
        if ( $.isFunction($.fn[ 'select2' ]) ) {

            $(".select2-remote:not(.select2-hidden-accessible)").each(function () {
                var _self = $(this);
                _self.select2({
                    ajax: {
                        url: _self.data().url,
                        dataType: 'json',
                        delay: 250,
                        placeholder: _self.data().placeholder ? _self.data().placeholder : 'Search',
                        data: function (params) {
                            if (_self.data().sendValueOf)
                                return {
                                    q: params.term, // search term
                                    page: params.page,
                                    data: $(_self.data().sendValueOf).val()
                                };
                            else
                                return {
                                    q: params.term, // search term
                                    page: params.page
                                };
                        },
                        cache: true
                    },
                    minimumInputLength: 1
                });
            });

            $(".tags:not(.select2-hidden-accessible)").each(function () {
                var _self = $(this);
                var allowTags = _self.data().allowNewTags?_self.data().allowNewTags:false;

                var maxSelectionLength = _self.data().maximumSelectionLength ? _self.data().maximumSelectionLength : Infinity;

                _self.select2({
                    // enable tagging
                    tags: allowTags,
                    maximumSelectionLength: maxSelectionLength,
                    minimumInputLength: 1
                });
            });

            $(".tags-select:not(.select2-hidden-accessible)").each(function () {
            var _self = $(this);
            var allowTags = _self.data().allowNewTags;

            var maxSelectionLength = _self.data().maximumSelectionLength ? _self.data().maximumSelectionLength : Infinity;

            _self.select2({
                // enable tagging
                tags: allowTags,
                maximumSelectionLength: maxSelectionLength,
                ajax: {
                    url: _self.data().url,
                    dataType: 'json',
                    delay: 250,
                    placeholder: _self.data().placeholder ? _self.data().placeholder : 'Search',
                    matcher: function (params, data) {
                        // If there are no search terms, return all of the data
                        if ($.trim(params.term) === '') {
                            return data;
                        }

                        // `params.term` should be the term that is used for searching
                        // `data.text` is the text that is displayed for the data object
                        if (data.text.indexOf(params.term) > -1) {
                            var modifiedData = $.extend({}, data, true);
                            //match was found then just show it.
                            // modifiedData.text += ' (matched)';

                            // You can return modified objects from here
                            // This includes matching the `children` how you want in nested data sets
                            return modifiedData;
                        }
                        else {
                            //there is not match found , suggest adding NEW Tag.
                            modifiedData.text += '[NEW]';
                            return modifiedData;
                        }

                        // Return `null` if the term should not be displayed
                        return null;
                    },
                    data: function (params) {
                        if (_self.data().sendValueOf)
                            return {
                                q: params.term, // search term
                                page: params.page,
                                data: $(_self.data().sendValueOf).val()
                            };
                        else
                            return {
                                q: params.term, // search term
                                page: params.page
                            };
                    },
                    cache: true
                },
                minimumInputLength: 1
            });
        });

        }
    //}

    //initSelect2Objects();


}).apply(this, [ jQuery ]);
