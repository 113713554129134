(function($){

    $(document).ready(function(){
        if ($.fn.ckeditor) {
            CKEDITOR.basePath = installationFolder+"/vendor/unisharp/laravel-ckeditor/";
            $('.ckeditor').ckeditor({
                toolbar: [
                    ['Image','-','Bold', 'Italic', 'Strike', '-', 'RemoveFormat', '-', 'Undo', 'Redo', '-', 'Cut', 'Copy', 'Paste', '-', 'NumberedList', 'BulletedList']
                ],
                enterMode: CKEDITOR.ENTER_BR,
                shiftEnterMode: CKEDITOR.ENTER_P,
                filebrowserImageBrowseUrl: route_prefix + '?type=Images',
                filebrowserImageUploadUrl: route_prefix + '/upload?type=Images&_token='+_token,
                filebrowserBrowseUrl: route_prefix + '?type=Files',
                filebrowserUploadUrl: route_prefix + '/upload?type=Files&_token='+_token
            });
        }
    });

})(jQuery);
