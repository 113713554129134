
/**
 * Will block elements in an unique fashion for the whole app, layer on top of blockui for style customization
 */
(function(theme, $) {

    'use strict';

    /** This will have global scope since are the same for all elements, thats the idea.
     *
     * @type {{opts: {}, initialize: Modal.initialize}}
     */
    var opts = {
        message:  '<h1><i class="fa fa-spinner fa-pulse"></i></h1>',
        css: {
            padding:        0,
            margin:         0,
            width:          '30%',
            top:            '40%',
            left:           '35%',
            textAlign:      'center',
            color:          '#fff',
            border:         '0',
            backgroundColor:'0',
            cursor:         'wait'
        },
        ignoreIfBlocked: true
    };

    var Block = {
        initialize: function(){
            $.blockUI.defaults.css = opts.css;
            $.blockUI.defaults.message = '<h1><i class="fa fa-spinner fa-pulse"></i></h1>';
            $.blockUI.defaults.ignoreIfBlocked = true;

            return this;

        },
    };

    var PluginBlock = function() {

        return Block.initialize();

    };

    // expose to scope as PluginBlock
    $.extend(theme, {

        PluginBlock: PluginBlock

    });

    Block.initialize();

}).apply(this, [window.theme, jQuery ]);

