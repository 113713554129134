// Scroll to Top
(function( $) {
    'use strict';

    var   PluginScrollToTop = {

        defaults: {
            wrapper: $('body'),
            offset: 150,
            buttonClass: 'scroll-to-top',
            iconClass: 'fa fa-chevron-up',
            delay: 500,
            visibleMobile: false,
            label: false
        },
        blocks: {},
        initialize: function(opts) {
            if ($('.scroll-to-top').length) return;
            this
                .setOptions(opts)
                .build()
                .quickNavigation()
                .addScrollButton()
                .fillBlocksData()
                .events();

            return this;
        },

        setOptions: function(opts) {
            this.options = $.extend(true, {}, this.defaults, opts);

            return this;
        },

        markup: null,

        build: function() {
            var self = this,
                $el;

            var $markup = $('<div />').addClass(self.options.buttonClass);
            // Visible Mobile
            if (!self.options.visibleMobile) {
                $markup.addClass('hidden-mobile');
            }

            self.markup = $markup;

            this.options.wrapper.append($markup);


            return this;
        },

        addScrollButton: function(){
            var self = this;
            // Base HTML Markup
            var $el = $('<a />')
                .attr({
                    'href': 'javascript:;',
                })
                .append(
                    $('<i />')
                        .addClass(self.options.iconClass)
                );

            // Label
            if (self.options.label) {
                $el.append(
                    $('<span />').html(self.options.label)
                );
            }
            self.markup.append($el);

            self.$el = $el;

            return self;
        },
        events: function() {
            var self = this,
                _isScrolling = false;

            // Click Element Action
            self.$el.on('click', function(e) {
                e.preventDefault();
                $('body, html').animate({
                    scrollTop: 0
                }, self.options.delay);
                return false;
            });

            self.markup.find('.quick-nav-item').on('click', function(e){
                var _self = $(this);
                e.preventDefault();

                $('body, html').animate({
                    scrollTop: $(_self.attr('href')).position().top
                }, self.options.delay);
                return false;
            });

            $('.panel').on( 'panel:toggle', $.proxy(self.fillBlocksData, self));

            // Show/Hide Button on Window Scroll event.
            $(window).scroll(function() {

                if (!_isScrolling) {

                    _isScrolling = true;

                    if ($(window).scrollTop() > self.options.offset) {

                        self.markup.stop(true, true).addClass('visible');
                        _isScrolling = false;

                        var $currentActive = self.markup.find('.active:first');
                        if ($currentActive)
                            $currentActive = $currentActive.attr('id');

                        $.each(self.blocks, function(key, value){

                            //var $scrollTop = $(window).scrollTop() - $('.header').height() - $('.page-header').height() + 20;
                            var $scrollTop = $(window).scrollTop()+ 20;

                            if ($scrollTop >= value.top && $scrollTop <= value.bottom && ('nav'+key) != $currentActive){

                                if ($currentActive) self.markup.find('.active:first').removeClass('active');

                                $('#nav'+key).addClass('active');

                                return;
                            }
                        });


                    } else {

                        self.markup.stop(true, true).removeClass('visible');
                        _isScrolling = false;

                    }

                }

            });

            setTimeout($.proxy(self.fillBlocksData, self), 300);

            return this;
        },
        fillBlocksData: function(){
            var _self = this;
            $('[data-quick-nav-label]').each(function(){
                var $item = $(this);
                var $itemId = $item.attr('id');

                //var delta = $('.page-header').height() + $('.header').height();
                var delta = 0;

                _self.blocks[$itemId] = {
                    "top": $item.position().top + delta,
                    "bottom": $item.position().top + delta + $item.height(),
                };
            });

            return _self;
        },
        quickNavigation: function(){
            var _self = this;
            $('[data-quick-nav-label]').each(function(){
                var $item = $(this);
                var $itemId;
                var $navItem = $('<a />').attr({
                    'href': '#'+($itemId=$item.attr('id')),
                    'id': 'nav'+$itemId,
                    'class': 'quick-nav-item mr-sm ml-sm text-xs text-uppercase'
                })
                .text($item.data().quickNavLabel);

                _self.markup.append($navItem);



            });
            return this;
        }
    }

    PluginScrollToTop.initialize();

})(jQuery);

